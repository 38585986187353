import { Link } from "gatsby"
import tw from "twin.macro"
import { UpArrow } from "../Icons/UpArrow"
import * as React from "react"
import { authCheck, isBrowser } from "../../helpers"



const EmailBanner: React.FC = () => {
    return (
        
        <Link 
            to="/verify-email"
            css={[tw`w-full bg-red-300 text-white text-center h-12 flex justify-center content-center items-center sticky top-0 z-40`]}
            className="group"
        >
            <span css={[tw`group-hover:scale-105 flex items-center`]}>
                <p>Please verify your email address</p>
                <UpArrow css={[tw`text-white rotate-90 h-4 ml-4`]} />
            </span>
        </Link>
        
    )
}

export default EmailBanner