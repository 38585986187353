import styled, { StyledComponent } from "@emotion/styled"
import { GatsbyLinkProps, Link, navigate } from "gatsby"
import { AnchorHTMLAttributes, ButtonHTMLAttributes } from "react"
import tw from "twin.macro"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents"
import Loader from "../Loader/Loader"
import { Button as ButtonProps, ButtonVariations } from "./buttons.d"

const StyledButton: StyledComponent<ButtonVariations> = styled.button(
  ({ primary, dark, secondary, disabled, large, outline }) => [
    tw`text-lg text-center px-6 whitespace-nowrap py-2 mt-5 h-12 rounded-[79px] relative border border-2 text-white hover:scale-105 transition`,
    large && tw`mx-auto text-2xl px-16 xl:px-24 py-4 my-5 hover:shadow-lg`,
    primary &&
      tw`text-white bg-red-300 border-red-300 hover:bg-red-500 hover:border-red-500 px-6 `,
    dark && tw`bg-red-700 hover:bg-red-500 hover:border-red-500 border-red-700`,
    secondary &&
      tw`bg-transparent border-gray-100 hover:bg-red-500 hover:border-red-500 `,
    disabled && tw`bg-gray-700 border border-2 text-white`,
    outline &&
      tw`bg-transparent border-red-300 hover:bg-red-500 hover:border-red-500 text-red-300 hover:text-white`,
  ]
)

const StyledButtonLink = StyledButton.withComponent(Link)
const StyledButtonA = StyledButton.withComponent("a")

export const Button: React.FC<
  ButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  loading,
  children,
  onClick,
  eventName,
  eventMessage,
  visitorID,
  ...remainingProps
}) => {
  return (
    <StyledButton
      onClick={e => {
        e.preventDefault()
        AnalyticsEventsClient.event({
          eventType: "click",
          eventName,
          eventMessage,
          visitorID
        })
        onClick(e)
      }}
      {...remainingProps}
    >
      {children}
      {loading && <Loader />}
    </StyledButton>
  )
}

export const ButtonLink: React.FC<ButtonProps & GatsbyLinkProps<{}>> = ({
  children,
  to,
  eventName,
  eventMessage,
  visitorID,
  state,
  ...remainingProps
}) => {
  return (
    <StyledButtonLink
      to={""} 
      state={""}
      onClick={e => {
        e.preventDefault()
        AnalyticsEventsClient.event({
          eventType: "click",
          eventName,
          eventMessage,
          visitorID
        })
        navigate(to)
      } }
      {...remainingProps}    >
      {children}
    </StyledButtonLink>
  )
}

export const ButtonA: React.FC<
  ButtonProps & AnchorHTMLAttributes<HTMLAnchorElement>
> = ({ children, href, eventName, eventMessage, visitorID, ...remainingProps }) => {
  return (
    <StyledButtonA
      {...remainingProps}
      target="_blank"
      onClick={e => {
        e.preventDefault()
        AnalyticsEventsClient.event({
          eventType: "click",
          eventName,
          eventMessage,
          visitorID
        })
        window.open(href, "_blank")
      }}
    >
      {children}
    </StyledButtonA>
  )
}
