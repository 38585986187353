import * as React from "react"
import { Link } from "gatsby"
import tw from "twin.macro"
import { usePrimaryNavigation } from "../../hooks/usePrimaryNavigation"
import { NavItem } from "./navigation.d"

const Primary: React.FC = () => {
  const { navigation } = usePrimaryNavigation()

  return (
    <nav css={[tw`hidden md:flex relative items-end `]}>
      {/* <pre>{JSON.stringify(location, null, 2)}</pre> */}
      <ul css={[tw`ml-4`]}>
        {navigation.items.map((item: NavItem, index: React.Key) => {
          const active =
            typeof window !== "undefined"
              ? window.location.pathname === item.url
              : false
          return (
            <li
              key={index}
              css={[
                tw`cursor-pointer text-gray-700 inline-block mr-4 text-sm xl:text-base border-b-4 border-transparent hover:border-red-300`,
                active && tw`border-red-300`,
              ]}
            >
              <Link to={item.url}>{item.label}</Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default Primary
