export enum HTTP_METHOD {
    GET = 'get',
    POST = 'post',
    PUT = 'put',
    DELETE = 'delete'
}

export type AnalyticsEvent = {
    eventID: string
    eventConfigID: string
    eventConfigVersion: number
    eventType: string
    eventName: string
    message: string
    eventLocation: string
    clientCreationDate: string
    visitorID?: string
    sessionID?: string
}