import React, { useEffect, useState } from "react"
import { Hamburger } from "../Icons/Hamburger"
import tw from "twin.macro"
import { usePrimaryNavigation } from "../../hooks/usePrimaryNavigation"
import { Link } from "gatsby"
import { UserIcon } from "../Icons/User"
import { NavItem } from "./navigation.d"
import useAuth from "../../hooks/useAuth"


const Mobile: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [show, setShow] = useState(false)
  const [display, setDisplay] = useState(tw`hidden`)
  const { navigation } = usePrimaryNavigation()


  useEffect(() => {
    if (open) {
      setDisplay(tw`flex`)
      document.body.style.overflow = "hidden"
      document.body.style.position = "fixed"

      setTimeout(() => {
        setShow(open)
      }, 10)
    } else {
      setShow(open)
      document.body.style.position = "relative"

      setTimeout(() => {
        setDisplay(tw`hidden`)
        document.body.style.overflow = "scroll"
      }, 300)
    }
  }, [open])

  const { isAuthenticated, logout  } = useAuth();


  return (
    <div css={[tw`flex justify-end items-end pt-3 pr-16 content-end z-50`]}>
     {isAuthenticated && <Link to="/profile">
        <UserIcon css={[tw`cursor-pointer text-gray-700` ]}/>
      </Link>}
       <div
        css={[
          tw`absolute top-0 right-0 h-full w-full bg-blue-700 bg-opacity-0 transition duration-300 overflow-hidden pointer-events-none`,
          show && tw`bg-opacity-90 fixed z-50 pointer-events-auto`,
        ]}
      >
        <div
          css={[
            tw`flex relative flex-row transform translate-x-full transition duration-300 ease-out h-full w-full`,
            show && tw`translate-x-1/4`,
          ]}
        >
          <button
            onClick={() => setOpen(!open)}
            css={[tw`absolute right-full py-3 pointer-events-auto`]}
          >
            <Hamburger
              css={[tw`mr-0 h-8 text-gray-700`, show && tw`text-white`]}
            />
          </button>
          <div
            css={[
              tw`bg-gray-300 w-3/4 relative right-0 top-0 h-full p-4 ml-3 relative overflow-hidden`,
              display,
            ]}
          >
           <nav css={[tw`w-full z-40`]}>
              {isAuthenticated ? navigation.items.map((item: NavItem, index: React.Key) => (
                <Link
                  key={index}
                  onClick={() => setOpen(false)}
                  to={item.url}
                  css={[
                    tw`block text-white font-semibold p-4 border-opacity-25 border-b`,
                  ]}
                >
                  {item.label}
                </Link>
              )): null}
              {isAuthenticated ?  <Link to="/questionnaire">
                <p css={[ tw`block text-white font-semibold p-4 border-opacity-25 border-b` ]} onClick={() => setOpen(false)}
                  > Questionnaire</p>
              </Link> : null}
              <div css={[tw`text-right text-white p-4 hover:text-red-300`]}>{isAuthenticated ? <p onClick={() => logout()}>Logout</p> : <Link to="/check-registration"><p onClick={()=> setOpen(false)}>Login</p></Link> }</div>
  
            </nav> 
          </div>
          
        </div>
      </div> 
    </div>
  )
}

export default Mobile
