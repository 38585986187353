import tw, { GlobalStyles as TWGlobal } from "twin.macro"
import { css, Global } from "@emotion/react"
import DownArrow from "../Icons/atoms-icons-down-arrow-grey.png"
import React from "react"

const GlobalStyles = () => {
  return (
    <>
      <TWGlobal />
      <Global
        styles={css`

          body {
              font-family: work-sans
              overflow: hidden !important
              background-color: colors.gray-700
          }
          
            h1 {
              ${tw`text-5xl xl:text-6xl`}
            }
  
            h2 {
              ${tw`text-4xl xl:text-5xl`}
            }
  
            h3 {
              ${tw`text-3xl lg:text-6xl`}
            }
  
            h4 {
              ${tw`text-2xl lg:text-3xl font-light`}
            }
  
            h5 {
              ${tw`text-2xl xl:text-4xl`}
            }
           
             h6 {
              ${tw`text-2xl xl:text-4xl`}

            }
            p {
              ${tw`font-medium`}
            }
            .no-scrollbar::-webkit-scrollbar {
              display: none;
            }
            
            .no-scrollbar {
              -ms-overflow-style: none; 
              scrollbar-width: none;  
              padding: 50px 0px 60px 0px
            }

          
            .input {
              ${tw`p-3 rounded-md text-red-300 placeholder-transparent`}
             
            }

            .input:-webkit-autofill {
              -webkit-text-fill-color: #e5554f;
            }
            .input:autofill {
              color: #e5554f
            }
            .input-gray {
              ${tw`p-3 rounded-md text-red-300 placeholder-transparent`}
             
            }

            .input-gray:-webkit-autofill {
              -webkit-text-fill-color: #e5554f;
            }
            .input-gray:autofill {
              color: #e5554f
            }

            select {
              -webkit-appearance: none;  
              -moz-appearance: none;
              background-color: white;
              background-image: url(${DownArrow});          
              background-repeat: no-repeat;
              background-position-x: 95%;
              background-position-y: 50%;
              ${tw`p-3 rounded-md text-red-300 placeholder-transparent`}
            } 
            label {
              ${tw`text-gray-300 absolute translate-y-3 translate-x-2 pointer-events-none`}
              transform-origin:0 0

            }

            .input-container {
              transition: all .3s
            }
            .input-container:focus-within label, .input:not(:placeholder-shown) + label, select:not([value="default"]):valid + .label-white{
              transform: translateY(-20px) scale(0.8);
              color: white;
              transition: all .3s
            }
            .input-container-gray:focus-within label, .input-gray:not(:placeholder-shown) + label, select:not([value="default"]):valid + label{
              transform: translateY(-20px) scale(0.8);
              color: gray;
              transition: all .3s
            }

            .lg-button {
              ${tw`mx-auto bg-red-300 hover:scale-105 text-white text-2xl text-center px-16 xl:px-24 py-4 my-5 rounded-[79px]`}
            }

            .lg-button-blue {
              ${tw`mx-auto bg-blue-300 hover:scale-105 text-white text-2xl text-center px-16 xl:px-24 py-4 my-5 rounded-[79px]`}
            }
            
            .tab:hover .oval {
                width: 386px;
                height: 120px;
                margin: 2px 0 3px;
                -webkit-filter: blur(50px);
                filter: blur(50px);
                background-color: #f16a64;
            }

            .tab:hover .avgWage {
              text-shadow: 0 5px 4px #b2241e;
            }
            .tab:hover .subhead {
              text-shadow: 0 5px 4px #b2241e;
            }

            .red-highlight {
              ${tw`text-red-300 font-bold text-2xl lg:text-3xl`}
            }

            .emphasis {
              ${tw`text-3xl lg:text-6xl py-4`}
            }

            .filterRed {
              filter: invert(43%) sepia(30%) saturate(1746%) hue-rotate(319deg) brightness(96%) contrast(89%);
            }
            .filterGray {
              filter: invert(41%) sepia(5%) saturate(15%) hue-rotate(0deg) brightness(94%) contrast(92%);
            }

          `}
      />
    </>
  )
}

export default GlobalStyles
