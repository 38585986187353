import React from "react"
import tw from "twin.macro"

const Loader = () => {
  return (
    <svg
      css={[
        tw`absolute animate-spin h-5 w-5 top-1/2 right-4 text-white inline-block`,
        `margin-top: -0.5em;`,
      ]}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        css={[tw`opacity-25`]}
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
      <path
        css={[tw`opacity-75`]}
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  )
}

export default Loader
