import React from "react"

export const Close = ({
  color = "currentColor",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
    <svg width="46" height="40" viewBox="0 0 46 40" xmlns="http://www.w3.org/2000/svg"{...props}>
    <g fill={color} fillRule="nonzero">
        <path d="M45.009 0h-14.03a.487.487 0 0 0-.396.2L.066 39.242c-.199.319.079.757.475.757H14.85c.158 0 .317-.08.396-.2L45.525.758c.158-.319-.12-.757-.516-.757z"/>
        <path d="M.56 0h14.03c.16 0 .318.08.397.2l30.517 39.043c.198.319-.08.757-.476.757H30.721a.487.487 0 0 1-.396-.2L.045.758C-.113.438.165 0 .561 0z"/>
    </g>
</svg>

)
