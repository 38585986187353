import { Link } from "gatsby"
import * as React from "react"
import tw from "twin.macro"
import FooterData from "../../data/footer.yml"
import FooterBG from "../../images/atoms-footer-desktop@3x.jpg"
import useAuth from "../../hooks/useAuth";



const Footer: React.FC = () => {
  const options = FooterData
  const {  isAuthenticated } = useAuth();

  return (
    <footer css={[tw`bg-cover h-48 lg:h-96 block relative object-bottom`, `background-image: url(${FooterBG});`]}>
      <div css={[tw`container grid grid-cols-12 relative top-12 lg:top-48 mx-auto flex justify-center content-end  pb-12 lg:py-8 px-3`]}>
        <div css={[tw`flex justify-center text-center text-sm lg:text-base`]}>
          <Link className="nav-item" to={FooterData[1].url} css={[tw`cursor-pointer text-gray-700`]}>
          {FooterData[1].label}
          </Link>
          {isAuthenticated && <><p css={[tw`text-gray-700 px-2 lg:px-5`]}> | </p>
          <Link className="nav-item" to={FooterData[2].url} css={[tw`cursor-pointer text-gray-700`]}>
          {FooterData[2].label}
          </Link></>}
        </div>
      </div>
    </footer>
  )
}

export default Footer