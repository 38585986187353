const Logo = ({ ...props }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg 
      version="1.1" 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 684 180" 
      {...props}
    >
      <path fill="#FFFFFF" d="M148.673,128.173l19.264-108.675c0.058-0.328,0.335-0.572,0.668-0.588l28.633-1.402
        c0.46-0.022,0.821,0.388,0.741,0.841l-18.997,107.51c-0.842,5.261,1.262,8.208,5.893,8.208c1.286,0,2.67-0.291,3.754-0.676
        c0.536-0.19,1.055,0.309,0.908,0.858l-5.604,20.871c-0.069,0.256-0.282,0.457-0.54,0.517c-5.074,1.194-9.396,1.791-14.514,1.791
        C153.514,157.428,144.885,149.01,148.673,128.173"/>
      <path fill="#FFFFFF" d="M230.392,102.768c-0.129,0.51,0.188,1.026,0.642,1.074c1.127,0.119,2.485,0.119,3.972,0.119
        c19.575,0,30.098-6.62,30.098-15.936c0-5.64-3.578-9.316-9.682-9.316C245.73,78.708,234.22,87.651,230.392,102.768 M230.201,113.364
        c-0.768-0.641-1.261-0.388-1.149,0.644c1.227,11.297,9.101,19.637,24.685,19.637c13.117,0,22.543-3.346,30.531-7.776
        c0.542-0.3,1.183,0.177,1.043,0.78l-6.454,23.523c-0.046,0.198-0.168,0.366-0.344,0.467c-7.595,4.323-19.686,6.789-34.036,6.789
        c-28.836,0-46.094-16.627-46.094-42.936c0-32.835,24.625-58.723,59.565-58.723c23.782,0,36.412,12.417,36.412,29.677
        c0,19.155-18.101,33.256-43.568,33.256C242.403,118.702,234.706,116.974,230.201,113.364"/>
      <path fill="#E5554F" d="M402.723,58.085h-34.324c-0.388,0-0.731,0.19-0.92,0.511l-74.538,94.86
        c-0.458,0.784,0.214,1.848,1.163,1.848h34.924c0.391,0,0.736-0.194,0.922-0.516l73.934-94.849
        C404.346,59.155,403.674,58.085,402.723,58.085z"/>
      <polygon fill="#FFFFFF" points="329.893,106.429 345.619,134.685 366.76,107.563 351.176,79.343 "/>
      <path fill="#FFFFFF" d="M138.382,133.617l-5.604,20.87c-0.069,0.256-0.282,0.457-0.539,0.518c-4.169,0.981-7.851,1.536-11.863,1.711
        c-0.787,0.048-1.561,0.08-2.294,0.08c-0.048,0-0.091-0.007-0.139-0.007c-0.075,0-0.143,0.007-0.219,0.007
        c-9.731,0-16.746-3.391-19.63-11.161c-0.246-0.119-0.545-0.108-0.773,0.104c-7.886,7.346-17.919,11.688-29.332,11.688
        c-20.91,0-37.148-18.521-37.148-45.251c0-33.256,22.404-56.409,47.881-56.409c11.611,0,21.679,4.391,28.36,13.356l0.001,0.001
        c0.091,0.117,0.24,0.169,0.412,0.173c0.227,0.005,0.448-0.129,0.484-0.33l1.492-8.419c0.061-0.342,0.358-0.592,0.706-0.592h28.663
        c0.447,0,0.785,0.405,0.705,0.845l-11.72,64.424c-0.795,4.964,1.064,7.834,5.162,8.146c0.263,0.019,0.511,0.054,0.791,0.056
        c0.762-0.006,1.532-0.125,2.275-0.29c0.371-0.093,0.744-0.178,1.104-0.32c0.053-0.021,0.102-0.007,0.155-0.016
        c0.045-0.015,0.101-0.023,0.145-0.039C138.003,132.572,138.531,133.061,138.382,133.617z M87.285,82.42
        c-13.73-2.716-21.857,7.895-23.995,20.125c-2.341,13.388,1.802,25.447,13.924,28.052c10.459,2.247,20.72-3.814,23.995-20.125
        C103.884,97.146,99.242,84.785,87.285,82.42z"/>
      <g>
        <path fill="#FFFFFF" d="M389.249,134.476c-5.371,0-9.235-2.316-13.318-10.313l-29.629-53.652l-0.02-0.04
          c-5.597-11.2-12.027-14.923-23.403-14.923c-3.141,0-7.574,0.619-12.312,1.593c-0.269,0.055-0.487,0.259-0.558,0.524l-5.513,20.47
          c-0.106,0.394,0.191,0.781,0.599,0.78l2.104-0.005c4.725,0,8.807,2.105,12.887,9.892l29.652,53.276
          c5.63,10.92,10.74,15.758,23.617,15.758c4.295,0,8.772-0.847,13.737-1.782c0.276-0.052,0.5-0.259,0.57-0.531l5.367-20.76
          c0.115-0.444-0.273-0.854-0.722-0.762C391.333,134.203,389.889,134.476,389.249,134.476z"/>
      </g>
      <g>
        <g>
          <path fill="#FFFFFF" d="M467.437,63.129h-8.809v23.081h-5.423V63.129h-9.415l0.302-4.73h23.69L467.437,63.129z"/>
          <path fill="#FFFFFF" d="M492.783,82.306l-0.348,3.948c-0.606,0.174-1.475,0.348-2.777,0.348c-2.864,0-4.467-1.519-4.467-4.296
            v-7.072c0-2.994-1.693-4.73-4.252-4.73c-2.951,0-4.817,2.258-4.817,4.73v10.977h-5.206V58.095l5.206-0.304v10.458
            c1.301-1.475,3.429-2.429,6.119-2.429c4.989,0,8.157,3.296,8.157,8.763v6.466c0,1.041,0.563,1.475,1.301,1.475
            C492.177,82.523,492.522,82.393,492.783,82.306z"/>
          <path fill="#FFFFFF" d="M507.065,82.219c3.168,0,4.861-0.782,6.725-2.127l-0.476,4.817c-1.388,0.91-3.861,1.78-7.29,1.78
            c-6.42,0-10.846-3.863-10.846-10.023c0-6.206,4.684-10.846,11.107-10.846c5.162,0,7.939,2.733,7.939,6.42
            c0,4.209-3.168,6.638-8.548,6.681c-2.429,0-4.383-0.606-5.249-1.649C500.775,80.179,503.074,82.219,507.065,82.219z
              M500.427,75.451c6.464,0.261,8.722-1,8.722-3.038c0-1.432-1.128-2.255-2.864-2.255C503.378,70.158,500.862,72.196,500.427,75.451
            z"/>
          <path fill="#FFFFFF" d="M465.266,120.556l-2.212-5.597h-11.759l-2.212,5.597h-5.554l11.757-27.768h3.904l11.672,27.768H465.266z
              M453.031,110.62h8.287l-4.122-10.499L453.031,110.62z"/>
          <path fill="#FFFFFF" d="M481.502,116.652l-0.345,3.948c-0.869,0.261-1.78,0.348-2.69,0.348c-3.081,0-4.643-1.736-4.643-5.078
            V92.397l5.206-0.217v23.084c0,1.128,0.565,1.606,1.388,1.606C480.765,116.869,481.243,116.782,481.502,116.652z"/>
          <path fill="#FFFFFF" d="M508.104,116.652l-0.345,3.948c-0.608,0.174-1.693,0.348-2.603,0.348c-2.171,0-3.69-0.826-4.339-2.603
            c-1.78,1.734-4.209,2.69-6.899,2.69c-5.554,0-9.936-4.426-9.936-10.458c0-5.945,4.426-10.412,9.936-10.412
            c2.733,0,5.206,0.954,7.029,2.69l0.258-2.258h4.469v14.71c0,1.128,0.565,1.562,1.432,1.562
            C507.454,116.869,507.889,116.782,508.104,116.652z M500.555,110.576c0-3.339-2.386-5.727-5.597-5.727
            c-3.211,0-5.597,2.388-5.597,5.727c0,3.342,2.386,5.727,5.597,5.727C498.17,116.304,500.555,113.918,500.555,110.576z"/>
          <path fill="#FFFFFF" d="M533.971,110.576c0,5.901-4.469,10.371-10.023,10.371c-2.948,0-5.554-1.128-7.287-3.168l-0.304,2.777
            h-4.122V92.44l5.206-0.304v10.501c1.693-1.562,3.991-2.429,6.507-2.429C529.545,100.208,533.971,104.632,533.971,110.576z
              M528.679,110.576c0-3.209-2.386-5.684-5.597-5.684c-3.255,0-5.641,2.475-5.641,5.684c0,3.211,2.386,5.727,5.641,5.727
            C526.293,116.304,528.679,113.831,528.679,110.576z"/>
          <path fill="#FFFFFF" d="M561.01,116.652l-0.345,3.948c-0.609,0.174-1.693,0.348-2.603,0.348c-2.171,0-3.69-0.826-4.339-2.603
            c-1.78,1.734-4.209,2.69-6.899,2.69c-5.554,0-9.936-4.426-9.936-10.458c0-5.945,4.426-10.412,9.936-10.412
            c2.733,0,5.206,0.954,7.029,2.69l0.258-2.258h4.469v14.71c0,1.128,0.565,1.562,1.432,1.562
            C560.361,116.869,560.795,116.782,561.01,116.652z M553.462,110.576c0-3.339-2.386-5.727-5.597-5.727s-5.597,2.388-5.597,5.727
            c0,3.342,2.386,5.727,5.597,5.727S553.462,113.918,553.462,110.576z"/>
          <path fill="#FFFFFF" d="M600.765,116.652l-0.304,3.948c-0.693,0.174-1.516,0.348-2.777,0.348c-2.864,0-4.467-1.519-4.467-4.339
            v-6.855c0-2.951-1.649-4.904-4.209-4.904c-2.518,0-4.383,1.997-4.383,4.904v10.803h-5.206v-10.803
            c0-2.951-1.736-4.904-4.383-4.904c-2.69,0-4.6,1.997-4.6,4.904v10.803h-5.206v-14.145l-0.391-5.641l4.861-0.432l0.171,2.861
            c1.26-1.821,3.559-3.035,6.51-3.035c3.296,0,5.814,1.475,7.159,3.991c1.041-2.342,3.557-3.991,6.899-3.991
            c4.945,0,7.983,3.209,7.983,8.676v6.553c0,0.954,0.522,1.475,1.301,1.475C600.245,116.869,600.547,116.739,600.765,116.652z"/>
          <path fill="#FFFFFF" d="M627.33,116.652l-0.345,3.948c-0.608,0.174-1.693,0.348-2.603,0.348c-2.171,0-3.69-0.826-4.339-2.603
            c-1.78,1.734-4.209,2.69-6.899,2.69c-5.554,0-9.936-4.426-9.936-10.458c0-5.945,4.426-10.412,9.936-10.412
            c2.733,0,5.206,0.954,7.029,2.69l0.258-2.258h4.469v14.71c0,1.128,0.565,1.562,1.432,1.562
            C626.681,116.869,627.115,116.782,627.33,116.652z M619.782,110.576c0-3.339-2.386-5.727-5.597-5.727s-5.597,2.388-5.597,5.727
            c0,3.342,2.386,5.727,5.597,5.727S619.782,113.918,619.782,110.576z"/>
          <path fill="#FFFFFF" d="M466.092,150.299l-0.348,4.6h-14.014c-3.255,0-5.206-1.171-5.206-5.249v-22.562h19.003l-0.348,4.6H451.86
            v6.638h11.455l-0.348,4.556H451.86v6.16c0,0.782,0.304,1.258,1.258,1.258H466.092z"/>
          <path fill="#FFFFFF" d="M489.354,150.995l-0.348,3.948c-0.997,0.217-1.908,0.348-2.646,0.348c-2.212,0-3.383-0.826-4.597-2.559
            l-3.081-4.469l-4.339,6.638h-5.988l7.072-9.98l-3.081-4.296c-1.258-1.693-1.908-2.212-3.035-2.212
            c-0.261,0-0.608,0.087-0.956,0.217l0.348-3.904c0.954-0.261,1.91-0.391,2.603-0.391c2.084,0,3.342,0.693,4.6,2.603l3.081,4.469
            l4.337-6.466h5.945l-7.029,9.762l3.125,4.296c1.345,1.78,1.91,2.214,2.994,2.214C488.661,151.212,489.006,151.125,489.354,150.995
            z"/>
          <path fill="#FFFFFF" d="M514.874,144.92c0,5.988-4.513,10.458-10.067,10.458c-2.516,0-4.771-0.826-6.464-2.388v9.504h-5.162
            v-21.782l-0.391-5.554l4.861-0.476l0.171,2.733c1.736-1.867,4.209-2.907,6.986-2.907
            C510.448,134.508,514.874,138.931,514.874,144.92z M509.494,144.92c0-3.252-2.299-5.727-5.554-5.727
            c-3.298,0-5.597,2.516-5.597,5.727c0,3.255,2.299,5.727,5.597,5.727C507.196,150.647,509.494,148.174,509.494,144.92z"/>
          <path fill="#FFFFFF" d="M529.678,150.908c3.168,0,4.861-0.782,6.725-2.127l-0.476,4.817c-1.388,0.91-3.861,1.78-7.29,1.78
            c-6.42,0-10.846-3.863-10.846-10.023c0-6.206,4.684-10.846,11.107-10.846c5.162,0,7.939,2.733,7.939,6.42
            c0,4.209-3.168,6.638-8.548,6.681c-2.429,0-4.383-0.606-5.249-1.649C523.388,148.868,525.687,150.908,529.678,150.908z
              M523.04,144.14c6.464,0.261,8.722-1,8.722-3.038c0-1.432-1.128-2.255-2.864-2.255C525.991,138.847,523.475,140.885,523.04,144.14
            z"/>
          <path fill="#FFFFFF" d="M553.595,134.595l-0.261,4.945c-0.478-0.087-0.91-0.13-1.519-0.13c-3.339,0-5.293,2.386-5.293,5.771v9.719
            h-5.206v-14.145l-0.391-5.771l4.774-0.432l0.302,3.818c0.869-2.171,3.125-3.861,6.206-3.861
            C552.856,134.508,553.073,134.508,553.595,134.595z"/>
          <path fill="#FFFFFF" d="M556.377,128.563c0-1.867,1.432-3.255,3.255-3.255c1.823,0,3.255,1.345,3.255,3.211
            c0.043,1.91-1.432,3.255-3.255,3.255C557.809,131.774,556.42,130.386,556.377,128.563z M564.49,150.995l-0.302,3.948
            c-0.565,0.174-1.432,0.348-2.82,0.348c-2.82,0-4.426-1.606-4.426-4.426v-10.11l-0.304-5.641l5.51-0.432v15.055
            c0,1.041,0.565,1.475,1.258,1.475C563.884,151.212,564.275,151.082,564.49,150.995z"/>
          <path fill="#FFFFFF" d="M578.898,150.908c3.168,0,4.861-0.782,6.725-2.127l-0.476,4.817c-1.388,0.91-3.861,1.78-7.29,1.78
            c-6.42,0-10.846-3.863-10.846-10.023c0-6.206,4.684-10.846,11.107-10.846c5.162,0,7.939,2.733,7.939,6.42
            c0,4.209-3.168,6.638-8.548,6.681c-2.429,0-4.383-0.606-5.249-1.649C572.608,148.868,574.906,150.908,578.898,150.908z
              M572.26,144.14c6.464,0.261,8.722-1,8.722-3.038c0-1.432-1.128-2.255-2.864-2.255C575.211,138.847,572.695,140.885,572.26,144.14
            z"/>
          <path fill="#FFFFFF" d="M612.49,150.995l-0.304,3.948c-0.606,0.174-1.519,0.348-2.82,0.348c-2.907,0-4.426-1.649-4.426-4.426
            v-6.768c0-2.951-1.821-4.904-4.51-4.904c-2.733,0-4.687,1.997-4.687,4.904v10.803h-5.206v-14.145l-0.391-5.597l4.861-0.476
            l0.171,2.948c1.304-1.864,3.646-3.122,6.64-3.122c5.119,0,8.33,3.47,8.33,8.98v6.249c0,0.997,0.519,1.475,1.258,1.475
            C611.882,151.212,612.36,151.082,612.49,150.995z"/>
          <path fill="#FFFFFF" d="M614.929,144.92c0-5.858,4.728-10.412,10.89-10.412c2.04,0,3.644,0.302,4.989,0.823l0.608,5.206
            c-1.084-0.736-2.733-1.388-4.989-1.388c-3.69,0-6.249,2.432-6.249,5.771c0,3.298,2.559,5.727,6.162,5.727
            c2.429,0,4.035-0.608,5.119-1.301l-0.608,5.119c-1.302,0.478-2.733,0.913-5.032,0.913
            C619.355,155.377,614.929,150.734,614.929,144.92z"/>
          <path fill="#FFFFFF" d="M646,150.908c3.168,0,4.861-0.782,6.725-2.127l-0.476,4.817c-1.388,0.91-3.861,1.78-7.29,1.78
            c-6.42,0-10.846-3.863-10.846-10.023c0-6.206,4.684-10.846,11.107-10.846c5.162,0,7.939,2.733,7.939,6.42
            c0,4.209-3.168,6.638-8.548,6.681c-2.429,0-4.383-0.606-5.249-1.649C639.71,148.868,642.009,150.908,646,150.908z M639.362,144.14
            c6.464,0.261,8.722-1,8.722-3.038c0-1.432-1.128-2.255-2.864-2.255C642.313,138.847,639.797,140.885,639.362,144.14z"/>
        </g>
      </g>
      </svg>
  )
}

export default Logo
