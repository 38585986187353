import { navigate } from 'gatsby';
import { parsePhoneNumber, AsYouType, isValidPhoneNumber } from 'libphonenumber-js';
import { useContext } from 'react';
import { useJwt } from 'react-jwt';
import useAuth, { AuthContext } from './hooks/useAuth';


export const toUsd = (number: number) => {
    return `$${numberWithCommas(Math.round(number))}`
}
export const toUsdWithCents = (number: number) => {
    return `$${numberWithCommas(number)}`
}

export const numberWithCommas = (x: number) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

//updated to replace all spaces
export const sluggify = (string: string) => string?.toLowerCase().replace(/ /g, '-')

export const toPercent = (number: number) => Math.round(number * 100) + "%"

export const percentageDifferent = (num1: number, num2: number) => toPercent(((num2 - num1)) / num1)

export const validatePhoneNumber =(phoneNumber: string) => {
    try {
      const parsed = parsePhoneNumber(phoneNumber, 'US');
      return isValidPhoneNumber(parsed.number, 'US')
    } catch (e) {
      return false;
    }
  }
 export const getFormattedPhoneNumber =(text: string) => {
    let phoneNumber;
      let formatted = new AsYouType('US').input(text);
      // Don't let the auto formatter add a trailing paren
      // otherwise the user will not be able to delete the paren
      // and the formatter will keep adding it
      if (formatted.endsWith(')') && !text.endsWith(')')) {
        formatted = formatted.slice(0, formatted.lastIndexOf(')'));
      }
      phoneNumber = formatted;
    
    return phoneNumber;
  }

export const randomize = (arr: []) => {
    const randomIndex = Math.floor(Math.random() * arr.length);
    const item = arr[randomIndex];
    return item;
}

export const isBrowser = typeof window !== "undefined"

export const authCheck = () => {
  const { isAuthenticated } = useAuth()
  const { state }: any = useContext(AuthContext)
  const { isExpired } = useJwt(state?.token)
  
    if (!isAuthenticated && isBrowser || isExpired && isBrowser) {
      navigate("/")
    } else {
      return
    }
  }
