exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-b-badge-id-tsx": () => import("./../../../src/pages/b/[badgeId].tsx" /* webpackChunkName: "component---src-pages-b-badge-id-tsx" */),
  "component---src-pages-career-resources-tsx": () => import("./../../../src/pages/career-resources.tsx" /* webpackChunkName: "component---src-pages-career-resources-tsx" */),
  "component---src-pages-check-registration-tsx": () => import("./../../../src/pages/check-registration.tsx" /* webpackChunkName: "component---src-pages-check-registration-tsx" */),
  "component---src-pages-e-[guid]-tsx": () => import("./../../../src/pages/e/[guid].tsx" /* webpackChunkName: "component---src-pages-e-[guid]-tsx" */),
  "component---src-pages-education-resources-tsx": () => import("./../../../src/pages/education-resources.tsx" /* webpackChunkName: "component---src-pages-education-resources-tsx" */),
  "component---src-pages-find-your-career-tsx": () => import("./../../../src/pages/find-your-career.tsx" /* webpackChunkName: "component---src-pages-find-your-career-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lifestyle-resources-tsx": () => import("./../../../src/pages/lifestyle-resources.tsx" /* webpackChunkName: "component---src-pages-lifestyle-resources-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-password-tsx": () => import("./../../../src/pages/password.tsx" /* webpackChunkName: "component---src-pages-password-tsx" */),
  "component---src-pages-pdf-file-pdf-tsx": () => import("./../../../src/pages/pdf/[filePDF].tsx" /* webpackChunkName: "component---src-pages-pdf-file-pdf-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-questionnaire-tsx": () => import("./../../../src/pages/questionnaire.tsx" /* webpackChunkName: "component---src-pages-questionnaire-tsx" */),
  "component---src-pages-reset-password-tsx": () => import("./../../../src/pages/reset-password.tsx" /* webpackChunkName: "component---src-pages-reset-password-tsx" */),
  "component---src-pages-resource-pdfs-tsx": () => import("./../../../src/pages/resource-pdfs.tsx" /* webpackChunkName: "component---src-pages-resource-pdfs-tsx" */),
  "component---src-pages-sign-up-tsx": () => import("./../../../src/pages/sign-up.tsx" /* webpackChunkName: "component---src-pages-sign-up-tsx" */),
  "component---src-pages-sources-tsx": () => import("./../../../src/pages/sources.tsx" /* webpackChunkName: "component---src-pages-sources-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-v-visitor-id-tsx": () => import("./../../../src/pages/v/[visitorId].tsx" /* webpackChunkName: "component---src-pages-v-visitor-id-tsx" */),
  "component---src-pages-verify-email-tsx": () => import("./../../../src/pages/verify-email.tsx" /* webpackChunkName: "component---src-pages-verify-email-tsx" */),
  "component---src-templates-career-results-tsx": () => import("./../../../src/templates/career-results.tsx" /* webpackChunkName: "component---src-templates-career-results-tsx" */),
  "component---src-templates-education-results-tsx": () => import("./../../../src/templates/education-results.tsx" /* webpackChunkName: "component---src-templates-education-results-tsx" */),
  "component---src-templates-industry-tsx": () => import("./../../../src/templates/industry.tsx" /* webpackChunkName: "component---src-templates-industry-tsx" */),
  "component---src-templates-lifestyle-results-tsx": () => import("./../../../src/templates/lifestyle-results.tsx" /* webpackChunkName: "component---src-templates-lifestyle-results-tsx" */),
  "component---src-templates-role-tsx": () => import("./../../../src/templates/role.tsx" /* webpackChunkName: "component---src-templates-role-tsx" */)
}

