import React, { useEffect, useRef, useState } from "react"
import { useAuth0 } from "@auth0/auth0-react"
import tw from "twin.macro"
import { UserIcon } from "../Icons/User"
import { Link } from "gatsby"
import useAuth from "../../hooks/useAuth"
import Dropdown from "../Dropdown"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents"

const ProfileDropdown: React.FC = () => {
  const { logout } = useAuth()
  const [open, setOpen] = useState(false)  

  const useOutsideAlerter= (ref: { current: { contains: (arg0: any) => any } }) => {
    useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false)
        }
      }
      document?.addEventListener("mousedown", handleClickOutside);
      return () => {
        document?.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef}>
    <button
      onClick={() =>setOpen(!open)}
      css={[
        tw`hidden lg:flex items-end content-end justify-end text-red-300 relative `,
      ]}
    >
      <UserIcon css={[tw`cursor-pointer hover:text-gray-700`]} />
     
    </button>
      {open && 
          <div css={[tw`absolute bg-white p-1 h-[10.25rem] w-60 mt-3 right-0 top-12 rounded z-30`]}>
              <Link to="/profile">
                  <p css={[tw`hover:bg-red-300 hover:text-white py-3 pl-3 hover:rounded text-xl font-bold text-gray-300`]} onClick={() => {setOpen(false); 
                  AnalyticsEventsClient.event({
                    eventType: "click",
                    eventName: "click_profile",
                    eventMessage: "User selected profile",
                    visitorID: localStorage.getItem("visitorID")
                  })}}>Profile</p>
              </Link>
              <Link to="/questionnaire">
                  <p css={[tw`hover:bg-red-300 hover:text-white py-3 pl-3 hover:rounded text-xl font-bold text-gray-300`]} onClick={() => {setOpen(false); 
                  AnalyticsEventsClient.event({
                    eventType: "click",
                    eventName: "click_questionnaire",
                    eventMessage: "User selected questionnaire",
                    visitorID: localStorage.getItem("visitorID")
                  })}}>Questionnaire</p>
              </Link>
              <p css={[tw`hover:bg-red-300 hover:text-white py-3 pl-3 hover:rounded text-xl font-bold text-gray-300`]} onClick={logout}>Logout</p>
          </div>
          
          }
     </div>
  )
}

export default ProfileDropdown
