import * as React from "react"
import tw from "twin.macro"
import { useRef, useState } from "react"
import { UpArrow } from "../Icons/UpArrow"
import { ResourceDropdownProps } from "./Navigation.d"
import { Link } from "gatsby"
import useAuth from "../../hooks/useAuth"

const Resources: React.FC<ResourceDropdownProps> = () => {
  const [open, setOpen] = useState(false)
  const { logout } = useAuth()

  const useOutsideAlerter= (ref: { current: { contains: (arg0: any) => any } }) => {
    React.useEffect(() => {
      function handleClickOutside(event: { target: any }) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false)
        }
      }
      document?.addEventListener("mousedown", handleClickOutside);
      return () => {
        document?.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef}>
    <button
      onClick={() =>setOpen(!open)}
      css={[
        tw`hidden lg:flex items-end content-end justify-end text-red-300 relative `,
      ]}
    >
  <h1 css={[tw`cursor-pointer text-2xl text-gray-700 font-bold pr-3 flex content-end items-baseline`]} className="nav-item">Resources</h1>
        <UpArrow css={open ? [tw`transition-transform duration-500 text-gray-700 mb-2`] : [tw`rotate-180 transition-transform duration-500 text-gray-700 mb-2`]} />     
    </button>
      {open && 
          <div css={[tw`absolute bg-white p-1 h-[10.25rem] w-60 mt-3 top-12 rounded z-30`]}>
              <Link to="/career-resources">
                  <p css={[tw`hover:bg-red-300 hover:text-white py-3 pl-3 hover:rounded text-xl font-bold text-gray-300`]} onClick={() => {setOpen(false)}}>Career</p>
              </Link>
              <Link to="/education-resources">
                  <p css={[tw`hover:bg-red-300 hover:text-white py-3 pl-3 hover:rounded text-xl font-bold text-gray-300`]} onClick={() => {setOpen(false)}}>Education</p>
              </Link>
              <Link to="/lifestyle-resources">
                  <p css={[tw`hover:bg-red-300 hover:text-white py-3 pl-3 hover:rounded text-xl font-bold text-gray-300`]} onClick={() => {setOpen(false)}}>Lifestyle</p>
              </Link>
          </div>
          
          }
     </div>
    
  )
}

export default Resources

