import React from "react"

export const Check = ({
  color = "currentColor",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="27"
    height="19"
    viewBox="0 0 27 19"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.283 0 11.087 12.294l-7.76-7.823L0 7.824 11.091 19 26.61 3.353z"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
)
