import React from "react"
import tw from "twin.macro"
import { UserIcon } from "../Icons/User"
import { Link } from "gatsby"

const LoginButton: React.FC = () => {
  return (
    <Link to="/check-registration">
      <div
        css={[
          tw`hidden lg:flex flex-col col-span-1 justify-center justify-items-center text-gray-700  hover:text-red-300`,
        ]}
      >
        <UserIcon css={[tw`cursor-pointer  mx-auto`]} />
        <p css={[tw`hidden lg:flex cursor-pointer mx-auto text-center`]}>
          Log In
        </p>
      </div>
    </Link>
  )
}

export default LoginButton
