import React, { createContext, useContext, useReducer, useState } from "react"
import GlobalStyles from "../GlobalStyles/GlobalStyles"
import Footer from "../Footer"
import Header from "../Header"
import { LayoutProps } from "./Layout.d"
import tw from "twin.macro"
import useAuth from "../../hooks/useAuth"
import Questionnaire from "../Questionnaire"
import EmailBanner from "../EmailBanner"


export const selectionsContext = createContext({})

const initialState = {
  career: "",
  industry: "",
  role: "",
  id: 0,
  salary: 0,
  url: "",
  count: 1,
}

function reducer(
  state: { count: number },
  action: { type: string; career: string; careerId: number ; industry: string; industryId: number; role: string; id: number, salary: number, region:{}}
) {
  switch (action.type) {
    case "UPDATE_CAREER":
      return {
        ...state,
        career: action.career,
        careerId: action.careerId
      }
    case "UPDATE_INDUSTRY":
      return {
        ...state,
        industry: action.industry,
        industryId: action.industryId
      }
    case "UPDATE_ROLE":
      return {
        ...state,
        role: action.role,
        id: action.id,
        salary: action.salary
      }
    case "REGION":
      return {
        ...state,
        region: action.region
      }
    case "step":
      return {
        ...state,
        count: state.count + 1,
      }
    case "reset":
      default:
      return initialState
  }
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)
  const { isAuthenticated } = useAuth();
  const location = window?.location.pathname
  const currentToken = localStorage.getItem("token")
  let defaultState = initialState
  if (currentToken && currentToken.length > 0) {
    defaultState = {
      ...defaultState
    }
  }
    const EmailStatus = localStorage.getItem("status")


  return (
      <selectionsContext.Provider value={{ state, dispatch }}>
        <GlobalStyles />
        {location.includes(`/v`) || location.includes(`/landing-page`) || location.includes(`/pdf`) ?  null : <Header />}
        <main css={[tw`bg-gray-100`]}>{children}{location.includes(`/v`) || location.includes(`/landing-page`) || location.includes(`/pdf`) ? null : isAuthenticated && <Questionnaire/>}</main>
        {location.includes(`/v`) || location.includes(`/landing-page`) || location.includes(`/pdf`) ? null : <Footer />}

      </selectionsContext.Provider>
    
  )
}

export default Layout
