import React, { createContext, useContext, useEffect, useReducer } from "react"
import { navigate } from "gatsby"
import { isBrowser } from "../helpers"
import { selectionsContext } from "../components/Layout"

let DEFAULT_STATE = {
  token: null as string,
  user: {},
  loggedIn: false,
  showQuestionnaire: false
}

const reducer = (
  state: { token: string; user: {}; loggedIn: boolean },
  action: any
) => {
  switch (action.type) {
    case "CARRY_EMAIL":
      return { user: action.email }
    case "LOGIN":
      return {
        ...state,
        token: action.token,
        user: action.user,
        loggedIn: true,
        showQuestionnaire: true
      }
    case "LOGOUT":
      return { ...state, token: null, user: {}, loggedIn: false, showQuestionnaire: false}
    default:
      return DEFAULT_STATE
  }
}

export const AuthContext = createContext(DEFAULT_STATE)

export const AuthProvider: React.FC = ({ children }) => {
  const currentToken = localStorage.getItem("token")
  let initialState = DEFAULT_STATE
  if (currentToken && currentToken.length > 0) {
    initialState = {
      ...initialState,
      token: currentToken,
      loggedIn: true,
    }
  }
  const [state, dispatch] = useReducer(reducer, initialState)
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuth = () => {
  const { state, dispatch }: any = useContext(AuthContext)
  const {  dispatch: setReset }: any = useContext(selectionsContext)
  const isAuthenticated = state?.loggedIn

  const login = async (form: any) => {
    try {
      return fetch(`${process.env.GATSBY_API_ROOT_URL}/auth/login`, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(form),
      })
        .then(res => res.json())
        .then(data => {
          dispatch({
            type: "LOGIN",
            token: data.tokenPayload.access_token,
            user: data.user,
          })
          localStorage.setItem("token", data.tokenPayload.access_token)
          localStorage.setItem("status", data.user.emailStatus )
          localStorage.setItem("email", data.user.email )
          navigate("/")
          return true
        })
    } catch (e) {
      throw e
    }
  }
  const logout = () => {
    
    // Avoid failed builds due to window not existing
    if (isBrowser) {
      localStorage.removeItem("token")
      localStorage.removeItem("visitorID")
      localStorage.removeItem("status")
      localStorage.removeItem("email")
      setReset({ type: "reset"})
      dispatch({ type: "LOGOUT" })
      navigate("/")
    }
  }

  return { state, isAuthenticated, login, logout }
}

export default useAuth
