import { PageProps } from "gatsby"
import React from "react"

export const UserIcon = ({
  color = "currentColor",
  ...props
}: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="25"
    height="28"
    viewBox="0 0 26 29"
    xmlns="http://www.w3.org/2000/svg"
    overflow={"visible"}
    stroke={color}
    {...props}
  >
    <g fillRule="nonzero" strokeWidth="2" fill="none">
      <path d="M19.39 16.105H5.61c-3.093 0-5.61 2.512-5.61 5.6v4.62c0 .649.531 1.177 1.184 1.177h22.63c.654 0 1.186-.528 1.186-1.178v-4.618c0-3.089-2.517-5.601-5.61-5.601z" />
      <path d="M12.625 16.105c4.48 0 8.125-3.613 8.125-8.053C20.75 3.612 17.105 0 12.625 0S4.5 3.612 4.5 8.052c0 4.44 3.645 8.053 8.125 8.053z" />
    </g>
  </svg>
)
