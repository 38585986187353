import React, { Component } from 'react';
import { navigate } from "gatsby";
import { useContext, useEffect, useState } from "react";
import { AnalyticsEvent, HTTP_METHOD } from "./clients.d";

class AnalyticsEventsClient {   

    public static event = async ({ eventType, eventName, eventMessage = "", visitorID }: { eventType: string, eventName: string, eventMessage?: string, visitorID?: string }): Promise<boolean> => {
        try {
            const eventData = AnalyticsEventsClient.generateAnalyticsEvent({
                eventType, eventName, message: eventMessage, visitorID
            })
            await fetch(`${process.env.GATSBY_API_ROOT_URL}/analytics-events`, {
                method: HTTP_METHOD.POST,
                headers: {
                    accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(eventData)
            })
            return true
        } catch (error) {
            throw error
        }
        
    }

    public static generateAnalyticsEvent = (eventData: Partial<AnalyticsEvent>): AnalyticsEvent => {
       
        return {
            eventID: process.env.GATSBY_EVENT_ID,
            eventConfigID: process.env.GATSBY_EVENT_CONFIG_ID,
            eventConfigVersion: Number(process.env.GATSBY_EVENT_CONFIG_VERSION),
            eventLocation: "Web",
            clientCreationDate: new Date().toISOString(),
            eventType: "string",
            eventName: "string",
            message: "string",
            visitorID: null,
            ...eventData
        }
    }
}

export default AnalyticsEventsClient

