import React from "react"
import Layout from "./src/components/Layout"
import { AuthProvider } from "./src/hooks/useAuth"

import "@fontsource/work-sans/500.css"
import "@fontsource/work-sans/700.css"
import { navigate } from "gatsby"

export const wrapPageElement = ({ element }) => {
  return <Layout>{element}</Layout>
}

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
)
