import * as React from "react"
import { Link } from "gatsby"
import Navigation from "../Navigation"
import Logo from "../Logo"
import tw from "twin.macro"
import FindYourCareer from "../FindYourCareer/index"
import { useContext, useEffect, useState } from "react"
import Dropdown from "../Dropdown"
import LoginButton from "../Login/LoginButton"
import useAuth, { AuthContext } from "../../hooks/useAuth"
import ProfileDropdown from "../Profile"
import Resources from "../Navigation/Resources"
import { sluggify } from "../../helpers"
import { selectionsContext } from "../Layout"
import AnalyticsEventsClient from "../../clients/AnalyticsEvents"
import EmailBanner from "../EmailBanner"

const Header: React.FC = () => {
  const [open, setOpen] = useState(false)
  const { isAuthenticated } = useAuth()
  const [show, setShow] = useState(true)
  const { state, dispatch }: any = useContext(selectionsContext)
  const EmailStatus = localStorage.getItem("status")

  useEffect(() => {
    const location = window?.location.pathname
    if(location.includes("career-results") || location.includes("education-results") || location.includes("lifestyle-results") ){
      setShow(false)
    } else {
      setShow(true)
    }
  }, [window?.location.pathname])


  return (
    <div>
    
    {EmailStatus == "NotVerified" && <EmailBanner/>}

    <header css={[tw`relative bg-gray-100 px-4 2xl:px-10 pt-5 pb-2 w-full`]}>
      <nav
        css={[
          tw`container relative mx-auto h-auto pb-3 grid grid-cols-2  lg:grid-cols-12 2xl:px-8`,
        ]}
      >
        <Link
          css={[tw`font-semibold cursor-pointer col-span-1 lg:col-span-2 z-50`]}
          to="/"
        >
          <Logo />
        </Link> 
       {isAuthenticated ?  <div css={[tw`hidden lg:flex lg:col-span-9 justify-end items-end content-end gap-5 `]} >
          <Link to={state?.role !== "" ?  `/career-results/${sluggify(state?.career)}/${sluggify(state?.industry)}/${sluggify(state?.role)}`: "/find-your-career" }>
            <h1 
            css={[tw`cursor-pointer text-2xl font-bold pr-3 text-red-300 content-end items-baseline`]} 
            className="nav-item"
            onClick={()=>  
              AnalyticsEventsClient.event({
                eventType: "click",
                eventName: "click_start_career",
                eventMessage: "store the button click event Find Your Career",
                visitorID: localStorage.getItem("visitorID")
              })}>
              Find Your Career
            </h1>
            </Link> 
          <Resources />
        </div> : null}
        <div css={[tw`visible col-span-1 h-auto lg:hidden justify-end justify-items-end items-end `]}>
          <Navigation.Mobile />
        </div>
        <div
          css={[
            tw`col-span-1 col-start-12 flex content-end items-end justify-end`,
          ]}
        >
          {isAuthenticated ? <ProfileDropdown /> : <LoginButton />}
        </div>
      </nav>
    </header>
    </div>
  )
}

export default Header
